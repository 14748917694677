.page-valuans {
  background: $blue-darker;

  .team-section {
    @include backgroundShape($blue-normal, 0, 64, 64, 64, 64, 20, 0, 54);
    color: $white;
    .subtitle { text-align: center; color: $orange; font-weight: 700; }
    .extra-subtitle { text-align: center; margin-bottom: 60px; }
  }

  .didier-thalmann-section {
    @include backgroundShape($blue-normal, 0, 0, 64, 0, 64, 64, 0, 28);
    color: $white;
    text-align: center;

    .image-container { width: 272px; margin: 0 auto; }
    h3 { font-size: 1.4rem; color: $orange; font-weight: bold; margin-bottom: 8px; }
    h4 { font-size: 1.1rem; color: $orange; }
    img { display: block; margin: auto; width: 22px; margin-bottom: 6px; }
    h5 { font-size: 1rem; font-weight: bold; }
  }

  .methodologie-section {
    @include backgroundShape($blue-normal, 0, 64, 64, 64, 64, 20, 0, 50);
    color: $white;
    .subtitle { text-align: center; color: $orange; font-weight: 700; }
    .extra-subtitle { text-align: center; margin-bottom: 40px; }

    .cascade {

      .item {
        text-align: center;
        display: block;
        max-width: 220px;
        margin: 0 auto 30px auto;
        h3 { color: $orange; font-size: 1.2rem; font-weight: bold; margin-top: 12px; }
      }

      .circle-special {
        position: relative;
        width: 40px;
        height: 40px;
        line-height: 38px;
        margin: 0 auto;
        font-weight: bold;

        .circle {
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 50%;
          color: $orange-normal;
          border: 3px solid $orange-normal;
          background: $white;
        }

        .bg-circle {
          border-radius: 50%;
          position: absolute;
          width: 100%;
          height: 100%;
          background: $orange-normal;
          transform: translate(4px, 4px);
        }
      }

      &>div:nth-child(1) .item { margin-top: 250px; h3 { color: #6d6e71; } .circle-special { .circle {border-color: #6d6e71; color: #6d6e71; } .bg-circle { background: #95979a; } } }
      &>div:nth-child(2) .item { margin-top: 200px; h3 { color: #035a99;  } .circle-special { .circle {border-color: #035a99; color: #035a99; } .bg-circle { background: #5c7bb0; } } }
      &>div:nth-child(3) .item { margin-top: 150px; h3 { color: #8e9fce; } .circle-special { .circle {border-color: #3d6bb0; color: #8e9fce; } .bg-circle { background: #748bc3; } } }
      &>div:nth-child(4) .item { margin-top: 100px; h3 { color: #1696ba; } .circle-special { .circle {border-color: #008cbe; color: #1696ba; } .bg-circle { background: #19a6ce; } } }
      &>div:nth-child(5) .item { margin-top: 50px; h3 { color: #f2844f; } .circle-special { .circle {border-color: #f2844f; color: #f2844f; } .bg-circle { background: #f6a67b; } } }
      &>div:nth-child(6) .item { margin-top: 0px; h3 { color: #ed5d46; } .circle-special { .circle {border-color: #ed5d46; color: #ed5d46; } .bg-circle { background: #f38c71; } } }

      @include media-breakpoint-down(lg) {
        .item {
          margin-top: 0 !important;
          h3 { height: auto; }
        }
      }
    }
  }

}