.footer {
  background: $blue-darker;
  color: $white;
  .subtitle { text-align: center; line-height: 1.6; font-size: 1.2rem; }

  a {
    transition: all .2s ease-in-out;
    text-decoration: none;
    &:hover { color: $orange; }
  }

  #footer-map {
    margin-top: 48px;
    width: 100%;
    height: 300px;
    background: $gray-darker;
  }

  .footer-nav {
    margin-top: 48px;
    ul {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      margin-bottom: 0;
      font-size: 1.1rem;
    }
    li {
      margin-left: 20px; margin-bottom: 20px;
      &:first-child { margin-left: 0; }
    }

    @include media-breakpoint-down(sm) {
      li { width: 50%; margin-left: 0; }
    }
    @include media-breakpoint-down(xs) {
      li { width: 100%; margin-bottom: 10px; }
      li:last-child { margin-bottom: 20px; }
    }
  }

  .legals {
    width: 100%;
    border-top: 1px solid $white;
    text-align: center;
    padding-top: 16px;
    font-size: 0.9rem;
    .address { font-size: 1rem; margin-bottom: 9px; }
    .copyrights { font-size: 0.8rem; }
  }
}

body.page-template-vos-objectifs-vos-priorites .footer {
  background: $blue-normal;
}

body.home .footer {
  background: $blue-darker;
  @include backgroundShape($blue-normal, 0, 64, 64, 64, 64, 50, 0, 64);
}

body.page-template-valuans .footer {
  background: $blue-darker;
  @include backgroundShape($blue-normal, 0, 0, 64, 0, 64, 42, 0, 28);
}

body.page-template-reference .footer {
  background: $bg-lightblue-normal;
}

body.page-template-landing.even-sections .footer {
  background: $bg-lightblue-darker;
}
body.page-template-landing.odd-sections .footer {
  background: $bg-lightblue-normal;
}

body.blog .footer,
body.single .footer,
body.category .footer {
  background: $bg-gray-normal;
  color: $blue-darker;
  .section-title { color: $blue-darker; }
  @include backgroundShape($bg-gray-darker, 0, 64, 64, 64, 64, 50, 0, 64);
  a { color: $blue-darker; }
  .legals { border-color: $blue-darker; }
}