.page-reference {
  background: $bg-lightblue-normal;

  .section-title { color: $blue-darker; }

  .methode-et-mise-en-oeuvre {
    text-align: center;
    @include backgroundShape($bg-lightblue-darker, 0, 64, 64, 64, 64, 20, 0, 58);

    .intro-paragraph { font-size: 1.2rem; font-weight: bold; color: $white; margin-bottom: 40px; }

    .steps-title { font-size: 1.3rem; font-weight: bold; color: $blue-normal; margin-bottom: -8px; margin-top: 26px; }

    .steps {
      li {
        margin-top: 24px;
        display: block;
        color: $blue-normal;
        position: relative;

        i { position: absolute; left: 50%; top: 100%; margin-top: 2px; font-size: 1.2rem; transform: translateX(-50%); color: $white; }
      }
    }
  }

  .exemple-client {
    @include backgroundShape($bg-lightblue-darker, 0, 0, 64, 0, 64, 64, 0, 22);

    .title-paragraph-bloc { color: $blue-normal; text-align: center; margin-bottom: 32px; }
    .title-paragraph-bloc h4 { font-size: 1.3rem; font-weight: bold; margin-bottom: 16px; }
  }

  .scroll-down-toggle i { color: $blue-darker; }


}