.phone-cta {
  display: flex;
  align-items: center;
  background: $orange-normal;
  color: $white;
  font-weight: 700;
  font-size: 1.2rem;
  position: fixed;
  right: 0;
  top: 200px;
  z-index: 98;
  padding: 8px 16px 8px 0px;
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  transform: translateX(calc(100% - 42px));
  transition: all .3s ease-out;
  i { font-size: 1.5em; }
  button { padding-left: 16px; padding-right: 16px; }
  a:hover { text-decoration: none; color: inherit; }

  &.active { transform: translateX(0); }

  &:after {
    content: ' ';
    border: 5px solid #fff;
    width: 150%;
    height: 100%;
    position: absolute;
    transform: translate(-12px, -3px);
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    pointer-events: none;
  }

  @include media-breakpoint-down(md) {
    & { top: 100px; }
  }
}