.page-blog {
  background: $bg-gray-darker;

  .fullpage-section {
    @include backgroundShape($bg-gray-normal, 0, 64, 64, 64, 64, 62, 0, 56);
  }

  .fullpage-wrapper { padding-bottom: 180px; }

  .categories-sidebar {
    background: $bg-gray-shade;

    h3 { font-size: 1.1em; font-weight: bold; text-transform: uppercase; padding: 16px 20px; margin-bottom: 0; }

    li {
      padding: 14px 20px;
      border-top: 1px solid $gray-normal;

      a {
        color: lighten($black, 20);
        &:hover { color: $blue-darker; text-decoration: none; }
        &.active { color: $blue-darker; font-weight: 700; }
      }
    }
  }

  .article-item {
    background: $bg-gray-normal;
    display: block;
    color: lighten($black, 24);
    margin-bottom: 26px;
    &:hover { color: $black; text-decoration: none;}

    h2 {
      font-size: 1.75rem;
    }

    .hover-zoom-in { overflow: hidden; }
    &:hover .bg-image {
      transform: scale(1.05);
    }
    .bg-image {
      width: 100%;
      padding-bottom: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: all .3s ease-out;
    }

    .article-pubdate {
      font-size: 0.9rem; margin-bottom: 14px;
      time { font-weight: bold; }
    }
  }

  .article-single {
    padding: 30px 30px;
    background: $bg-gray-normal;

    .article-title { margin-bottom: 8px; font-size: 2.3rem; font-weight: 700; }

    .article-pubdate {
      margin-bottom: 34px; font-size: 1.1rem; color: $gray-darker;
      time { font-weight: bold; }
    }

    .bg-image {
      width: 100%;
      padding-bottom: 58%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 26px;
    }
  }

  .related-articles {
    color: lighten($black, 20);
    hr { border-top: 3px solid $bg-gray-normal; }
  }

  .back-to-articles {
    background: $bg-gray-shade;
    margin-bottom: 30px;
    a {
      color: lighten($black, 20);
      display: block;
      padding: 16px 20px;
      &:hover { text-decoration: none; }
    }
  }

  .pagination {
    font-size: 1.1em;
    .page-numbers {
      padding: 6px 8px;
      margin-right: 6px;
      background: $bg-gray-normal;
      border-radius: 4px;
      color: lighten($black, 20);
      text-decoration: none;
      &.current { color: $orange-normal; }
    }
  }

}