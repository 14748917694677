@import "variables";
@import "mixins";
@import "reset";

/** Librairies */
@import "~bootstrap/scss/bootstrap";
@import "~ionicons/dist/scss/ionicons";
@import "~slick-carousel/slick/slick.scss";
@import "~wysiwyg.css/wysiwyg.sass";

/** Globals */
@import "wp-generated-classes";
@import "fonts";
@import "main";

/** Components */
@import "components/header";
@import "components/footer";
@import "components/phone-cta";
@import "components/fullpage-section";
@import "components/team-slider";
@import "components/items";

/** Pages */
@import "pages/home";
@import "pages/vos-objectifs-vos-priorites";
@import "pages/valuans";
@import "pages/blog";
@import "pages/offres-adaptees";
@import "pages/reference";
@import "pages/landing";
