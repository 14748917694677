.page-landing {

  background: $bg-lightblue-normal;

  .section-title { color: $blue-darker; }

  .datadock-mention { margin-top: 50px; color: $gray-darker; }

  .fullpage-section:nth-child(2n) {
    background: $bg-lightblue-darker;
    @include backgroundShape($bg-lightblue-normal, 0, 64, 64, 64, 64, 50, 0, 60);
  }

  .fullpage-section:nth-child(2n+1) {
    @include backgroundShape($bg-lightblue-darker, 0, 64, 64, 64, 64, 60, 0, 50);
  }

  .fullpage-section {
    color: $white;
  }

  .top-section {
    position: relative;

    .section-gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba(121,190,221,0.35) 0%,rgba(121,190,221,0.7) 46%,rgba(121,190,221,1) 100%);
    }
  }


  .happyforms-form__title { display: none; }

  .landing-cta {
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    transform: translateX(-50%);
    padding: 16px 46px 13px 44px;
    background: $orange-normal;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: $white;
    text-transform: uppercase;
    font-size: 0.9em;
    box-shadow: 0px 1px 8px rgba(0,0,0,0.3);
    transition: all .3s ease-in-out;
    z-index: 2;

    &:hover {
        .icon {
          animation: left-to-right .5s;
        }
    }

    .icon {
      display:inline-block;
      margin-right: 6px;
    }
  }

  .happyforms-part--recaptcha {
    width: 50%;
    float: left;
    margin-top: -14px;
    margin-bottom: 0;
  }
  .happyforms-part--submit {
    width: 50%;
    float: left;
  }

  .happyforms-button--submit {
    background: $orange-normal !important;
    padding: 16px 32px !important;
  }

  @include media-breakpoint-down(lg) {
    .happyforms-part--recaptcha, .happyforms-part--submit { float: none; width: 100%; }
  }
}

@keyframes left-to-right {
  0% {transform: rotateZ(0deg);}
  100% {transform: rotateZ(360deg);}
}