@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/helvetica-regular.woff2') format('woff2'),
       url('../fonts/helvetica-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/helvetica-bold.woff2') format('woff2'),
       url('../fonts/helvetica-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
