html, body {
  color: $black;
  background: $gray-lighter;
  font-family: 'Helvetica', Helvetica, Arial, 'sans-serif';
  font-size: 16px;
  line-height: 1.2;
}

button {
  background: none;
  border: 0;
  outline: 0;
  padding: 0;
  color: inherit;
  cursor: pointer;
  &:focus { outline: none; }
}

.txt-orange { color: $orange; }

.txt-sm { font-size: 0.8rem; }

.btn-plus { margin: auto; width: 20px; height: 20px; line-height: 21px; background: $orange; color: $white; font-size: 0.8em; border-radius: 7px; }

.wysiwyg {
  img {
    max-width: 100%;
  }
  a {
    color: $blue-normal;
  }
  strong, b {
    font-weight: bold;
  }
}

.datadock-mention {
  font-size: 0.7rem;
  font-style: italic;
  margin: 15px auto 0 auto; color: darken($white, 20);
  text-align: center;
}

button.btn-orange {
  background: $orange-normal;
  box-shadow: 0px 1px 8px rgba(0,0,0,0.3);
  color: $white;
  font-weight: bold;
  border-radius: 5px;
  border: 0;
  padding: 10px 16px;
  font-size: 13px;
  text-transform: uppercase;
  &:hover, &:focus { box-shadow: 0 1px 4px rgba(0,0,0,0.6); color: $white; }

  span {
    font-weight: normal;
  }
}

a.btn-catalog {
  background: #004C92;
  box-shadow: 0px 1px 8px rgba(0,0,0,0.3);
  color: $white;
  font-weight: bold;
  border-radius: 5px;
  border: 0;
  padding: 12px 18px;
  font-size: 13px;
  text-transform: uppercase;
  &:hover, &:focus { box-shadow: 0 1px 4px rgba(0,0,0,0.6); color: $white; }
  span {
    font-weight: normal;
  }
}

.cookie-banner {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: transparentize($orange-normal, 0.4);
  color: $white;
  padding: 14px 24px;
  z-index: 9999;
  box-shadow: 0px -2px 8px rgba(0,0,0,0.25);

  .cookie-banner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1180px;
    margin: auto;
  }

  .message { font-size: 1rem; margin-right: 12px; }

  button.btn-accept {
    background: $white;
    box-shadow: 0px 1px 8px rgba(0,0,0,0.3);
    color: $gray-darker;
    border-radius: 5px;
    border: 0;
    padding: 10px 16px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    &:hover, &:focus { box-shadow: 0 1px 4px rgba(0,0,0,0.9);}
  }
}

.ion-logo-instagram::before {
  font-weight: bold;
}

.ssb_inline-share_heading { font-size: 1.4rem !important; }
.simplesocialbuttons.simplesocial-simple-round button {
  height: 32px !important;
  line-height: 24px !important;
}
.simplesocialbuttons.simplesocial-simple-round button:after,
.simplesocialbuttons.simplesocial-simple-round button:before {
  line-height: 32px !important;
  width: 32px !important;
  height: 32px !important;
}
