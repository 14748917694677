@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/** Use http://jxnblk.com/paths/ to generate coordinates (M=x1,y1 / L=x2,y2 / L=x3,y3 / L=x4,y4) */
@mixin backgroundShape($color, $x1, $y1, $x2, $y2, $x3, $y3, $x4, $y4) {
  background-size: 100.05% 100.05%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23#{str-slice(inspect($color), 2, 7)}' preserveAspectRatio='none' viewBox='0 0 64 64'%3E%3Cpath d='M#{$x1} #{$y1} L#{$x2} #{$y2} L#{$x3} #{$y3} L#{$x4} #{$y4} Z' opacity='1'%3E%3C/path%3E%3C/svg%3E");
}
