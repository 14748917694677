.fullpage-section {
  width: 100%;
  min-height: calc(100vh - #{$headerHeight});
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;

  .top-section-title {
    color: $white;
    text-align: center;
    font-size: 3.8rem;
    font-weight: bold; line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 24px;
    overflow-wrap: break-word;
    @include media-breakpoint-down(md) {
      & { font-size: 2.8rem; }
    }
    @include media-breakpoint-down(sm) {
      & { font-size: 2rem; }
    }
    @include media-breakpoint-down(xs) {
      & { font-size: 1.8rem; }
    }
  }

  .top-section-paragraph {
    color: $white;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.4;
  }

  .section-title {
    color: $white;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 64px;
    @include media-breakpoint-down(md) {
      margin-bottom: 48px;
      & { font-size: 2.5rem; }
    }
    @include media-breakpoint-down(sm) {
      & { font-size: 1.8rem; }
    }
    @include media-breakpoint-down(xs) {
      & { font-size: 1.6rem; }
    }
  }

  .section-subtitle {
    color: $white;
    text-align: center;
    font-size: 1.4rem;
  }

  .fullpage-wrapper {
    padding: 52px 0 100px 0;
    width: 100%;
  }

  .scroll-down-toggle {
    color: $orange;
    font-size: 3rem;
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include headerMobileBreakpoint {
    min-height: calc(100vh - #{$headerHeightMobile});
  }
}