.team-slider {
  margin: 0 26px;

  &.without-bio .bio { display: none !important; }

  .item-wrapper {
    margin: 0 auto;
    width: 272px;
    display: block;
    text-align: center;

    h4 { color: $orange; font-size: 1.2rem; font-weight: bold; }
  }

  .slick-prev, .slick-next { font-size: 0; position: absolute; top: 82px;  color: $orange-normal; border: 0; background: none; z-index: 1; }
  .slick-prev:hover:after, .slick-next:hover:after { color: $orange; }
  .slick-prev { left: 20px; }
  .slick-prev:after { content: "\F3CF"; font-family: "Ionicons"; line-height: 1; font-size: 3rem; display: block; transform: translateX(-32px); }
  .slick-next { right: 20px; text-align: right; }
  .slick-next:after { content: "\F3D1"; font-family: "Ionicons"; line-height: 1; font-size: 3rem; display: block; transform: translateX(40px); }

  .bio { display: none; width: 460px; min-height: 125px; transform: translateX(-20.5%); margin-top: 20px; }
  .slick-center .bio { display: block; }

  @include media-breakpoint-down(xs) {
    .bio { width: 100%; transform: translateX(0%); }
  }

  @media (max-width: 400px) {
    .item-wrapper { width: 235px; }
  }
}
