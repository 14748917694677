.page-home {
  background: $blue-darker;

  .vos-objectifs-vos-priorites-section {
    @include backgroundShape($blue-normal, 0, 64, 64, 64, 64, 26, 0, 54);

    .cascade {
      margin-top: 26px;

      .item {
        text-align: center;
        margin-bottom: 30px;
        display: block;
        img { margin-bottom: 14px; height: 90px; transition: all .2s ease-in-out; }
        h3 { color: $orange; font-size: 1.2rem; font-weight: bold; height: 80px; }
        &:hover {
          text-decoration: none;
          img { transform: scale(1.05); }
        }
      }

      &>div:nth-child(6) .item { margin-top: 0; }
      &>div:nth-child(5) .item { margin-top: 50px; }
      &>div:nth-child(4) .item { margin-top: 100px; }
      &>div:nth-child(3) .item { margin-top: 150px; }
      &>div:nth-child(2) .item { margin-top: 200px; }
      &>div:nth-child(1) .item { margin-top: 250px; }

      @include media-breakpoint-down(lg) {
        .item {
          margin-top: 0 !important;
          h3 { height: auto; }
        }
      }
    }
  }

  .des-offres-adaptees-section {
    @include backgroundShape($blue-normal, 0, 0, 64, 0, 64, 64, 0, 32);

    .item-wrapper {
      max-width: 300px; margin: 20px auto 40px auto; display: block;
      color: $white;
      &:hover {
        text-decoration: none;
        .circle:after {
          transform: translate(-1%, -2%);
        }
      }

      .circle {
        position: relative;
        border-radius: 50%;
        width: 90%;
        margin-left: 5%;
        height: auto;
        padding-top: 90%;
        background: $orange;

        &:after {
          transition: all .3s ease-in-out;
          content: ' ';
          border: 8px solid #fff;
          width: 101%;
          height: 104%;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-5%, -5%);
          border-radius: inherit;
          pointer-events: none;
        }
      }

      p { margin-top: 20px; text-align: center; }

      h3 { position: absolute; top: 50%; left: 50%; transform: translate(-55%, -50%); text-align: center; font-size: 1.3rem; fotn-weight: bold; }
    }
  }

  .valuans-section {
    @include backgroundShape($blue-normal, 0, 64, 64, 64, 64, 40, 0, 64);
    .description { text-align: center; margin-top: 40px; color: $white; font-size: 1.1rem; }
  }

  .feed-section {
    @include backgroundShape($blue-normal, 0, 0, 64, 0, 64, 64, 0, 42);

    .row { margin-top: 78px; }

    .article-container {
      width: 90%;
      margin-left: 5%;
      display: block;

      &:hover {
        text-decoration: none;
        .orange-bg { transform: translate(-4%, -5%); }
      }
    }

    .article-thumbnail {
      position: relative;
      background: $gray-darker;
      width: 80%;
      height: auto;
      padding-top: 48%;
      margin: auto;

      .image-bg {
        border: 5px solid $gray-lighter;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center;
      }

      .orange-bg {
        content: ' ';
        background: $orange;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-8%, -10%);
        transition: all .3s ease-in-out;
      }
    }
    .article-excerpt { margin-top: 14px; text-align: center; color: $white; font-size: 1.1rem; }
    .article-link {
      color: $orange; text-align: center; font-weight: bold; display: block;
      .plus { background: $orange; color: $white; border-radius: 5px; display: inline-block; font-size: 0.8em; padding: 0.14rem 0.25rem; margin-left: 5px; }
      &:hover { text-decoration: none; }
    }
  }

  .top-section-paragraph-special {
    margin-top: -34px;
    color: $white;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.4;
  }

}