.page-vos-objectifs-vos-priorites {
  background: $blue-darker;

  .vos-objectifs-section {
    color: $white;
  }

  .vos-priorites-section {
    @include backgroundShape($blue-normal, 0, 64, 64, 64, 64, 26, 0, 54);
    color: $white;
  }

}