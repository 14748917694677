$headerHeight: 120px;
$headerHeightMobile: 70px;

@mixin headerMobileBreakpoint {
  @media (max-width: 992px) {
    @content;
  }
}

.header {
  @include clearfix;
  background: $white;
  width: 100%;
  min-width: 320px;
  z-index: 99;
  position: fixed;
  top: 0;

  .container {
    position: relative;
  }

  .mobile-header-toggle {
    color: $blue-darker;
    position: absolute;
    font-size: 2.8rem;
    display: none;
  }

  .logo {
    display: block;
    height: 59px;
    margin: 10px 0 5px 0;
    text-align: center;
    img { height: 100%; text-align: center; }
  }

  .menu-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .nav-main {
    ul { margin-bottom: 0; display: flex; align-items: center; }

    ul:not(.sub-menu)>li.active>a { color: $orange-normal; }

    li {
      margin-right: 18px;
      font-weight: 700;
      position: relative;
      a {
        color: $blue-darker;
        text-decoration: none;
        transition: all .2s ease-in-out;
        display: block;
        line-height: 46px;
        font-size: 1.1rem;
      }

      &:hover {
        > a { color: $orange; }
        .sub-menu { display: block; }
      }

      .sub-menu {
        display: none;
        width: 520px;
        position: absolute;
        top: 100%;
        left: 0;
        li { display: inline-block; }
        li.active a { color: $orange-normal; }

        &:before {
          content: ' ';
          position: absolute;
          width: 9999px;
          height: 100%;
          transform: translateX(-50%);
          background: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .nav-social {
    width: 146px;
    ul { margin-bottom: 0; }
    li {
      display: inline-block;
      margin-left: 10px;
      a { font-size: 1.8rem; color: $gray-darker; transition: all .2s ease-in-out;  line-height: 46px; }
      a:hover {color: $orange; }
    }
  }

  @include headerMobileBreakpoint {
    .container { width: 100%; padding: 12px 20px; max-width: 100%; }
    .logo { height: 46px; margin: 0; }
    .mobile-header-toggle { display: block; }
    .menu-wrapper { display: none; }
  }
}

.header.mobile-menu {

  .menu-wrapper {
    border-top: 1px solid $gray-normal;
    margin-top: 12px;
    padding-top: 12px;
    display: block;
  }

  .nav-main {
    padding-bottom: 18px;
    ul { display: initial; }
    li { padding: 11px 0; }
    li a { line-height: normal; }
    li:first-child { display: none; }
    li .sub-menu {
      display: block;
      position: initial;
      width: auto;
      margin-left: 30px;
      li { display: block; }
      &:before { display: none; }
      li:first-child { display: block; }
    }
  }
  .nav-social {
    li {
      margin-left: 0; display: block; line-height: 1.2;
      a { font-size: 2rem; }
    }
  }
}

.header-spacer {
  height: $headerHeight;
  @include headerMobileBreakpoint {
    height: $headerHeightMobile;
  }
}
