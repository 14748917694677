.page-offres-adaptees {
  background: $blue-darker;

  .nouvelles-possibilites-section {
    color: $white;
    @include backgroundShape($blue-normal, 0, 64, 64, 64, 64, 26, 0, 54);

    .item-wrapper {
      width: 80%;
      margin: 0 auto 40px auto;
      text-align: center;
    }
  }

  .activite-dediee-section {
    color: $white;
    @include backgroundShape($blue-normal, 0, 0, 64, 0, 64, 64, 0, 32);
  }

  .ciblez-futurs-clients-section {
    color: $white;
    @include backgroundShape($blue-normal, 0, 64, 64, 64, 64, 26, 0, 54);
  }

  .doublez-competences-section {
    color: $white;
    @include backgroundShape($blue-normal, 0, 0, 64, 0, 64, 64, 0, 42);
  }

  .renforcez-communication-commerciale-section {
    color: $white;
    @include backgroundShape($blue-normal, 0, 64, 64, 64, 64, 26, 0, 54);
  }

  .augmentez-connaissance-marche-section {
    color: $white;
    @include backgroundShape($blue-normal, 0, 0, 64, 0, 64, 64, 0, 42);
  }

  .evaluations {
    color: $white;
    text-align: center;
    @include backgroundShape($blue-normal,0, 0, 64, 0, 64, 64, 0, 42);

    .intro { margin-bottom: 34px; }

    .item-wrapper {
      max-width: 300px; margin: 20px auto 40px auto; display: block;
      color: $white;
      &:hover {
        text-decoration: none;
        .circle:after {
          transform: translate(-1%, -2%);
        }
      }

      .circle {
        position: relative;
        border-radius: 50%;
        width: 90%;
        margin-left: 5%;
        height: auto;
        padding-top: 90%;
        background: $orange;

        @include media-breakpoint-down(sm) {
          width: 80%;
          padding-top: 80%;
        }

        &:after {
          transition: all .3s ease-in-out;
          content: ' ';
          border: 8px solid #fff;
          width: 101%;
          height: 104%;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-5%, -5%);
          border-radius: inherit;
          pointer-events: none;
        }
      }

      p { margin-top: 16px; text-align: center; font-size: 1.1rem; }

      .content { position: absolute; top: 50%; left: 50%; transform: translate(-55%, -50%); text-align: center; }
      h3 { font-size: 2.2rem; font-weight: bold; }

    }
  }

  .apprendre-pour-progresser-section {
    color: $white;
    text-align: center;
    @include backgroundShape($blue-normal, 0, 64, 64, 64, 64, 26, 0, 54);

    .intro { margin-bottom: 34px; }
    .axis { margin-top: 12px; font-weight: bold; }
  }

  .idees-de-croissance-section {
    color: $white;
    text-align: center;
    @include backgroundShape($blue-normal, 0, 0, 64, 0, 64, 64, 0, 42);

    .bullet { color: $orange-normal; display: block; margin-bottom: 2px; }
    .argument { margin-bottom: 4px; font-size: 1.1rem; }
  }

  .renforcer-strategie-section {
    color: $white;
    text-align: center;
    @include backgroundShape($blue-normal, 0, 64, 64, 64, 64, 50, 0, 64);

    .bullet { color: $orange-normal; display: block; margin-bottom: 2px; }
    .argument { margin-bottom: 4px; font-size: 1.1rem; }
  }

  .developper-chiffre-affaires-section {
    color: $white;
    text-align: center;
    @include backgroundShape($blue-normal, 0, 0, 64, 0, 64, 64, 0, 42);

    .bullet { color: $orange-normal; display: block; margin-bottom: 2px; }
    .argument { margin-bottom: 4px; font-size: 1.1rem; }
  }

}