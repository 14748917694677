.item-picto-title-desc {
  display: block;
  text-align: center;
  margin: 0 auto 40px auto;
  img { margin-bottom: 14px; height: 90px; transition: all .2s ease-in-out; }
  h3 { color: $orange; font-size: 1.2rem; font-weight: bold; margin-bottom: 12px; }
  &:hover {
    text-decoration: none;
    color: $white;
    img { transform: scale(1.05); }
  }
}

.circle-image-special {
  position: relative;
  background: $gray-normal;
  border-radius: 50%;
  width: 80%;
  height: auto;
  padding-top: 80%;
  margin-left: 10%;
  margin-bottom: 30px;

  .image-bg {
    background: $gray-normal;
    border: 6px solid $white;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
  }

  .orange-bg {
    border-radius: 50%;
    content: ' ';
    background: $orange;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(3%, 3%);
    transition: all .3s ease-in-out;
  }
}

.item-title-subtitle-desc-image {
  text-align: center;
  .title { font-size: 3rem; font-weight: bold; text-transform: uppercase; text-align: center; margin-bottom: 80px; }
  .subtitle { font-weight: 700; color: $orange; font-size: 1.2rem; margin-bottom: 14px; }
  .picto { width: 160px; margin-top: 22px; }
}

.item-definition {
  text-align: left;
  img { width: 100px; float: left; }
  .definition {
    float: right;
    width: calc(100% - 126px);
    h4 { color: $orange; font-size: 1.2rem; }
    p { font-size: 0.95rem; }
  }

  @include clearfix();
}