$black: #000;
$white: #fff;
$blue-darker: #004c92;
$blue-normal: #3b62ac;
$blue-lighter: #64b7db;
$orange-normal: #ef7d3a;
$gray-darker: #5d6268;
$gray-normal: #e8e6eb;
$gray-lighter: #eeedf0;

$bg-blue-normal: #3d6bb0;
$bg-blue-darker: #035a99;
$bg-lightblue-normal: #69badb;
$bg-lightblue-darker: #57b5d7;
$bg-gray-normal: #eae9ed;
$bg-gray-shade: #ebecee;
$bg-gray-darker: #e1e1e7;

// Bootstrap
$link-color: $white;
$container-max-widths: (
  "md": 840px
);
$grid-gutter-width: 36px;

// Wysiwyg.css
$border: $orange-normal;
